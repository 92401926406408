@import '../var';

.footer-items {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: apx(26px, $max);
  width: 100%;
  font-size: apx(24px, $max);
  font-weight: $regular;
  line-height: 155.556%;
  margin-top: apx(160px, $max);

  @media #{$media-max} {
    gap: ims(26px);
    margin-top: ims(160px);
    font-size: ims(24px);
  }

  @media #{$media-max-wrap} {
    gap: apx(20px, $max-wrap);
    margin-top: apx(120px, $max-wrap);
    font-size: apx(18px, $max-wrap);
  }

  @media #{$media-phone} {
    flex-direction: column;
    gap: apx(40px, $phone);
    margin-top: apx(80px, $phone);
    font-size: apx(18px, $phone);
  }

  h2 {
    display: block;
    font: inherit;
    color: #6A6A6A;
    padding: 0;
    margin: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: apx(13px, $max);
    width: 100%;

    @media #{$media-max} {
      gap: ims(13px);
    }

    @media #{$media-max-wrap} {
      gap: apx(6px, $max-wrap);
    }

    @media #{$media-phone} {
      gap: apx(6px, $phone);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: apx(13px, $max);
    width: 100%;

    @media #{$media-max} {
      gap: ims(13px);
    }

    @media #{$media-max-wrap} {
      gap: apx(16px, $max-wrap);
    }

    @media #{$media-phone} {
      gap: apx(16px, $phone);
    }

    &-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      gap: .25em;
    }

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: apx(6px, $max);
      width: 100%;

      @media #{$media-max} {
        gap: ims(6px);
      }

      @media #{$media-max-wrap} {
        gap: apx(6px, $max-wrap);
      }

      @media #{$media-phone} {
        gap: apx(6px, $phone);
      }

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: apx(13px, $max);

        @media #{$media-max} {
          gap: ims(13px);
        }

        @media #{$media-max-wrap} {
          gap: apx(10px, $max-wrap);
        }

        @media #{$media-phone} {
          gap: apx(10px, $phone);
        }
      }
    }
  }
}

.poweredBy {
  color: #6A6A6A;
  margin-top: auto;

  @media #{$media-phone} {
    margin-top: 1em;
  }
}