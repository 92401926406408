@import '../var';

.footer {
  display: block;
  width: 100%;
  border-radius: apx(64px, $max) apx(64px, $max) 0 0;
  background-color: #000;
  color: #fff;
  position: relative;
  z-index: 1;
  
  @media #{$media-max} {
    border-radius: ims(64px) ims(64px) 0 0;
  }

  @media #{$media-max-wrap} {
    border-radius: apx(48px, $max-wrap) apx(48px, $max-wrap) 0 0;
  }

  @media #{$media-phone} {
    border-radius: apx(32px, $phone) apx(32px, $phone) 0 0;
  }

  &__inner {
    display: block;
    width: 100%;
    max-width: $max-screen;
    margin-left: auto;
    margin-right: auto;
    padding: apx(120px, $max) apx(40px, $max);
    box-sizing: border-box;

    @media #{$media-max} {
      padding: ims(120px) ims(40px);
    }

    @media #{$media-max-wrap} {
      padding: apx(120px, $max-wrap) apx(40px, $max-wrap);
    }

    @media #{$media-phone} {
      padding: apx(80px, $phone) apx(20px, $phone);
    }
  }
}