@import '../var';

.title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: apx(10px, $max);
  width: 100%;
  font-size: apx(168px, $max);
  font-weight: $regular;
  line-height: 100%;
  text-transform: uppercase;
  white-space: nowrap;
  font-kerning: none;
  margin: 0;

  @media #{$media-max} {
    font-size: ims(168px);
    gap: ims(10px);
  }

  @media #{$media-max-wrap} {
    font-size: apx(124px, $max-wrap);
    gap: apx(8px, $max-wrap);
  }

  @media #{$media-phone} {
    font-size: apx(42px, $phone);
  }

  &--margin-bottom {
    margin-bottom: apx(120px, $max);

    @media #{$media-max} {
      margin-bottom: ims(120px);
      gap: ims(10px);
    }
  
    @media #{$media-max-wrap} {
      margin-bottom: apx(120px, $max-wrap);
    }
  
    @media #{$media-phone} {
      margin-bottom: apx(40px, $phone);
    }
  }

  &-line {
    display: flex;
    align-items: flex-start;
    width: 100%;

    @media #{$media-phone} {
      align-items: center;
    }

    &-text {
      display: block;
      width: auto;
    }

    &-insert {
      flex-shrink: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: apx(173px, $max);
      height: apx(117px, $max);
      border-radius: 1000px;
      overflow: hidden;
      margin-top: apx(16px, $max);

      @media #{$media-max} {
        width: ims(173px);
        height: ims(117px);
        margin-top: ims(16px);
      }

      @media #{$media-max-wrap} {
        width: apx(130px, $max-wrap);
        height: apx(88px, $max-wrap);
        margin-top: apx(8px, $max-wrap);
      }

      @media #{$media-phone} {
        width: apx(64px, $phone);
        height: apx(48px, $phone);
        margin-top: 0;
      }

      span {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: inherit;

        picture,
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &--left {
        margin-right: apx(28px, $max);
        order: -1;

        @media #{$media-max} {
          margin-right: ims(28px);
        }

        @media #{$media-max-wrap} {
          margin-right: apx(28px, $max-wrap);
        }
      }

      &--right {
        margin-left: apx(28px, $max);

        @media #{$media-max} {
          margin-left: ims(28px);
        }

        @media #{$media-max-wrap} {
          margin-left: apx(28px, $max-wrap);
        }
      }

    }
    
    // POSITIONS
    &--align-left {
      justify-content: flex-start;
    }
    
    &--align-right {
      justify-content: flex-end;
      text-align: right;
    }
    
    &--mobile-small {
      @media #{$media-phone} {
        font-size: apx(38px, $phone);
      }
    }
  }

  // BIG
  &--big {
    @media #{$media-phone} {
      font-size: apx(84px, $phone);
    }
  }

  &--big &-line {
    @media #{$media-phone} {
      align-items: flex-end;
    }
  }

  &--big &-line-insert {
    @media #{$media-phone} {
      width: apx(140px, $phone);
      height: apx(96px, $phone);
      margin-top: 0;
    }
  }

  &-counter {
    vertical-align: top;
    font-size: apx(28px, $max);
    line-height: apx(((168px - 28px) / 2), $max);
    margin-left: apx(27px, $max);
    user-select: none;

    @media #{$media-max} {
      font-size: ims(28px);
      line-height: ims(((168px - 28px) / 2));
      margin-left: ims(27px);
    }

    @media #{$media-max-wrap} {
      font-size: apx(28px, $max-wrap);
      line-height: apx(((124px - 28px) / 2), $max-wrap);
      margin-left: apx(27px, $max-wrap);
    }

    @media #{$media-phone} {
      font-size: apx(20px, $phone);
      line-height: apx(((42px - 20px) / 2), $phone);
      margin-left: apx(20px, $phone);
    }
  }
}