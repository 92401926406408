@import '../var';

:root {
  --header-height: #{apx(88px, $max)};

  @media #{$media-max} {
    --header-height: #{ims(88px)};
  }

  @media #{$media-max-wrap} {
    --header-height: #{apx(88px, $max-wrap)};
  }

  @media #{$media-phone} {
    --header-height: #{apx(80px, $phone)};
  }
}

.header {
  display: block;
  width: 100%;
  background-color: var(--bg-color, rgba(255, 255, 255, 0.4));
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0 1px 0 0 transparent; // FIX BACKDROP-FILTER FOR CHROME
  transition: background-color .3s ease;

  &__inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: $max-screen;
    height: var(--header-height);
    margin: 0 auto;
    padding: 0 apx(40px, $max);
    box-sizing: border-box;
    transition: 0.3s padding ease;

    @media #{$media-max} {
      padding: 0 ims(40px);
    }

    @media #{$media-max-wrap} {
      padding: 0 apx(40px, $max-wrap);
    }

    @media #{$media-phone} {
      padding: 0 apx(20px, $phone);
    }
  }

  &--scrolled {
    background-color: var(--bg-color, #fff);

    .header__inner {
      padding: apx(20px, $max) apx(40px, $max);

      @media #{$media-max} {
        padding: ims(20px) ims(40px);
      }

      @media #{$media-max-wrap} {
        padding: apx(20px, $max-wrap) apx(40px, $max-wrap);
      }

      @media #{$media-phone} {
        padding: apx(10px, $phone) apx(20px, $phone);
      }
    }
  }

  &__logo {
    flex-shrink: 0;
    display: block;
    width: apx(156px, $max);
    height: auto;
    text-decoration: none;
    cursor: pointer;
    margin-right: auto;

    @media #{$media-max} {
      width: ims(156px);
    }

    @media #{$media-max-wrap} {
      width: apx(156px, $max-wrap);
    }

    @media #{$media-phone} {
      width: apx(117px, $phone);
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &--show-burger {
    background-color: var(--bg-color, #fff);
    height: 100dvh;
  }


}

// BURGER
.burger-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: apx(64px, $max);
  height: apx(48px, $max);
  font-size: 0;
  cursor: pointer;
  transition: .3s $easeOutSine;
  transition-property: border-color, background-color;

  @media #{$media-max} {
    width: ims(64px);
    height: ims(48px);
    border-radius: ims(16px);
  }

  @media #{$media-max-wrap} {
    width: apx(64px, $max-wrap);
    height: apx(48px, $max-wrap);
    border-radius: apx(16px, $max-wrap);
  }

  @media #{$media-phone} {
    width: apx(48px, $phone);
    height: apx(48px, $phone);
    border-radius: apx(16px, $phone);

    &--open {
      border: 1.5px solid #000;
    }

    &--close {
      background-color: #f2f2f2;
    }
  }

  svg {
    display: block;
    width: apx(64px, $max);
    height: apx(48px, $max);

    @media #{$media-max} {
      width: ims(64px);
      height: ims(48px);
    }
  
    @media #{$media-max-wrap} {
      width: apx(64px, $max-wrap);
      height: apx(48px, $max-wrap);
    }
  
    @media #{$media-phone} {
      width: apx(24px, $phone);
      height: apx(24px, $phone);
    }
  }
}

.button-link {
  display: block;
  width: apx(48px, $phone);
  height: apx(48px, $phone);
  border-radius: apx(16px, $phone);
  background-color: #000;
  font-size: 0;
  margin-right: apx(16px, $phone);
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2748%27 height=%2748%27 viewbox=%270 0 48 48%27 fill=%27none%27%3E%3Cpath fill=%27%23fff%27 fill-rule=%27evenodd%27 d=%27M23.25 12v7A5.75 5.75 0 0 0 29 24.75h7v-1.5h-7A4.25 4.25 0 0 1 24.75 19v-7h-1.5Zm1.5 24v-7A5.75 5.75 0 0 0 19 23.25h-7v1.5h7A4.25 4.25 0 0 1 23.25 29v7h1.5Z%27 clip-rule=%27evenodd%27/%3E%3C/svg%3E");
    mix-blend-mode: difference;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
}

.header__side-left,
.header__side-middle,
.header__side-right {
  display: flex;
  align-items: center;
  width: 100%;
}

.header__side-middle {
  justify-content: center;
}

.header__side-right {
  justify-content: flex-end;
}