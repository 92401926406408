@import '../../var';

.overflow {
    display: block;
    width: 100%;
    height: 100dvh;
    background-color: #fff;
    padding-top: var(--header-offset, 0px);
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: $max-screen;
    min-height: calc(100dvh - var(--header-offset, 0px));
    margin: 0 auto;
    padding: apx(40px, $max);
    box-sizing: border-box;

    @media #{$media-max} {
        padding: ims(40px);
    }
  
    @media #{$media-max-wrap} {
        padding: apx(40px, $max-wrap);
    }

    @media #{$media-phone} {
        padding: apx(20px, $phone);
    }
}