@import '../../../var';

.banner__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: apx(54px, $max);

    @media #{$media-max} {
        margin-top: ims(54px);
    }

    @media #{$media-max-wrap} {
        margin-top: apx(54px, $max-wrap);

    }

    @media #{$media-phone} {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        gap: apx(20px, $phone);
    }

    &-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: apx(16px, $max);
        width: auto;
        max-width: 100%;
        font-family: inherit;
        text-decoration: none;
        color: inherit;
        cursor: pointer;

        @media #{$media-max} {
            gap: ims(16px);
        }

        @media #{$media-max-wrap} {
            gap: apx(16px, $max-wrap);
        }

        @media #{$media-phone} {
            gap: apx(12px, $phone);
        }

        &-text {
            display: block;
            white-space: nowrap;
            font-size: apx(18px, $max);
            font-weight: $regular;
            line-height: 133.333%;

            @media #{$media-max} {
                font-size: ims(18px);
            }

            @media #{$media-max-wrap} {
                font-size: apx(18px, $max-wrap);
            }

            @media #{$media-phone} {
                font-size: apx(16px, $phone);
            }
        }

        &-icon {
            display: block;
            width: apx(48px, $max);
            height: apx(48px, $max);
            border-radius: apx(16px, $max);
            border: 1px solid #000;
            background-color: #fff;
            background-image: linear-gradient(-15deg, black 50%, transparent calc(50% + 1px));
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: 100% 250%;
            box-sizing: border-box;
            overflow: hidden;
            position: relative;
            transition: background-position .6s $easeOutSine;

            @media #{$media-max} {
                width: ims(48px);
                height: ims(48px);
                border-radius: ims(16px);
            }

            @media #{$media-max-wrap} {
                width: apx(48px, $max-wrap);
                height: apx(48px, $max-wrap);
                border-radius: apx(16px, $max-wrap);
            }

            @media #{$media-phone} {
                width: apx(32px, $phone);
                height: apx(32px, $phone);
                border-radius: apx(10.67px, $phone);
            }

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background: no-repeat center center / contain;
                mix-blend-mode: difference;
            }

            &--geo {
                order: -1;

                &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M14 22.052c.019-5.57 4.511-10.07 10.034-10.052 5.523.019 9.985 4.55 9.966 10.12v.115c-.068 3.62-2.073 6.968-4.53 9.584a26.341 26.341 0 0 1-4.677 3.883 1.204 1.204 0 0 1-1.586 0 25.9 25.9 0 0 1-6.58-6.214A12.977 12.977 0 0 1 14 22.086v-.034Z' clip-rule='evenodd'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M24 26a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z'/%3E%3C/svg%3E");
                }
            }

            &--calendar {
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath stroke='%23fff' stroke-width='1.5' d='M30 13v4m-12-4v4m-2 6h16a4 4 0 0 0 0-8H16a4 4 0 0 0 0 8Zm2.4 10h11.2c2.24 0 3.36 0 4.216-.436a4 4 0 0 0 1.748-1.748C36 29.96 36 28.84 36 26.6v-5.2c0-2.24 0-3.36-.436-4.216a4 4 0 0 0-1.748-1.748C32.96 15 31.84 15 29.6 15H18.4c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748C12 18.04 12 19.16 12 21.4v5.2c0 2.24 0 3.36.436 4.216a4 4 0 0 0 1.748 1.748C15.04 33 16.16 33 18.4 33Z'/%3E%3C/svg%3E");
                }

                @media #{$media-phone} {
                    order: -1;
                }
            }
        }

        @media #{$media-only-desktop} {
            &:hover &-icon,
            &:active &-icon {
                background-position: 0 100%;
            }
        }
    }
}