@import '../../var';

.links {
    display: block;
    width: auto;
    margin-left: auto;
}

.list {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: apx(40px, $max);
    font-size: apx(18px, $max);
    font-weight: $regular;
    line-height: 133.333%;
    white-space: nowrap;

    @media #{$media-max} {
        gap: ims(40px);
        font-size: ims(18px);
    }

    @media #{$media-max-wrap} {
        gap: apx(40px, $max-wrap);
        font-size: apx(18px, $max-wrap);
    }

    @media #{$media-phone} {
        flex-direction: column;
        align-items: flex-start;
        gap: apx(16px, $phone);
        font-size: apx(18px, $phone);
    }

    & > li {
        height: auto;

        @media #{$media-phone} {
            width: 100%;
        }
    }
}

.button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: apx(16px, $max);
    height: auto;

    @media #{$media-max} {
        gap: ims(16px);
    }

    @media #{$media-max-wrap} {
        gap: apx(16px, $max-wrap);
    }

    @media #{$media-phone} {
        gap: apx(16px, $phone);
    }
}

.text {
    display: block;
    height: 100%;

    @media #{$media-phone} {
        display: none;
    }
}

.icon {
    display: block;
    width: apx(48px, $max);
    height: apx(48px, $max);
    border-radius: apx(16px, $max);
    border: 1.5px solid #000;
    background-color: #fff;
    background-image: linear-gradient(-15deg, #000 50%, transparent calc(50% + 1px));
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 250%;
    box-sizing: border-box;
    overflow: hidden;
    transition: background-position .6s $easeOutSine;
    position: relative;

    @media #{$media-max} {
        width: ims(48px);
        height: ims(48px);
        border-radius: ims(16px);
    }

    @media #{$media-max-wrap} {
        width: apx(48px, $max-wrap);
        height: apx(48px, $max-wrap);
        border-radius: apx(16px, $max-wrap);
    }

    @media #{$media-phone} {
        width: apx(48px, $phone);
        height: apx(48px, $phone);
        border-radius: apx(16px, $phone);
    }

    svg {
        display: block;
        width: 100%;
        height: 100%;
        mix-blend-mode: difference;

        path {
            &[fill]:not([fill="none"]) {
                fill: #fff;
            }

            &[stroke]:not([stroke="none"]) {
                stroke: #fff;
            }
        }
    }
}

@media #{$media-hover} {
    .button {
        cursor: pointer;

        &:hover, &:active {
            .icon {
                background-position: 0 100%;
            }
        }
    }
}