@use 'sass:math';

// MIXINS
@function apx($px, $break) {
  @return math.div($px, $break) * 100vw;
}

@function ims($px) {
  @return math.ceil($px * $imsRatio);
}

@mixin scroll-custom($thumb-color: #000, $track-color: transparent) {
  & {
    scrollbar-width: thin;
    scrollbar-color: $thumb-color $track-color;

    &::-webkit-scrollbar {
      height: 7px;
      width: 7px;

      &-track {
        border-radius: 7px;
        background-color: $track-color;
      }

      &-thumb {
        border-radius: 4px;
        background-color: $thumb-color;
      }
    }
  }
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

// VARIABLES
$max-screen: 2560px;
$max-height-screen: 1470px;
$max: 1920px;
$max-wrap: 1440px;
$phone: 375px;
$imsRatio: math.div($max-screen, $max);

// FONTS
$Mont: 'Mont', sans-serif;

// FONT WEIGHT
$regular: 400;
$semi-bold: 700;
$bold: 600;

// MEDIA
$media-max: 'screen and (min-width: #{$max-screen + 1})';
$media-max-wrap: 'screen and (max-width: #{$max-wrap + 1})';
$media-phone: 'screen and (max-width: 768px) and (orientation: portrait)';
$media-only-desktop: 'screen and (min-width: 768px) and (orientation: landscape)';

$media-hover: '(hover: hover) and (pointer: fine)';

// TIMING-FUNCTION
$easeOutSine: cubic-bezier(.33, 1, .68, 1);
