@import '../../../var';

.container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: apx(20px, $max);
    font-size: apx(48px, $max);
    font-weight: $regular;
    line-height: 133.333%;

    @media #{$media-max} {
        gap: 20px;
        font-size: 48px;
    }

    @media #{$media-max-wrap} {
        gap: apx(20px, $max-wrap);
        font-size: apx(48px, $max-wrap);
    }

    @media #{$media-phone} {
        gap: apx(16px, $phone);
        font-size: apx(24px, $phone);

        li {
            width: 100%;
            text-align: center;
        }
    }
}

.link {
    display: block;

    @media #{$media-phone} {
        width: 100%;
        position: relative;

        &::before {
            content: '';
            display: block;
            width: calc(100% + apx(16px, $phone));
            height: calc(100% + apx(16px, $phone));
            position: absolute;
            left: apx(-8px, $phone);
            top: apx(-8px, $phone);
        }
    }
}