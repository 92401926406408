@use 'scss-reset/reset';

@import './components/var';
@import './components/fonts';

body {
  display: block;
  width: 100%;
  min-height: 100vh;
  background-color: var(--bg-color, #fff);
  overflow: hidden auto;
  margin: 0;
  font-family: $Mont;
  font-weight: $regular;
  font-size: apx(16px, $max);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: var(--header-offset, 0px);

  @media #{$media-max} {
    font-size: 16px;
  }

  @media #{$media-max-wrap} {
    font-size: apx(16px, $max-wrap);
  }

  @media #{$media-phone} {
    font-size: apx(16px, $phone);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

main {
  width: 100%;
  overflow: hidden;
}

html.no-scroll, html[data-no-scroll]
html.no-scroll body, html[data-no-scroll] body {
	overflow: hidden !important;
}

#wrapfabtest {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-100%, 100%);
}

input, textarea, select {
  &:-webkit-autofill {
    &, &:focus, &:active, &:hover {
      background-color: initial !important;
      background-image: none !important;
      color: inherit !important;
      transition: background-color 99999s ease-in-out 0s !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
  }
}