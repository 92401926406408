@import '../var';

.cookies-notify {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: apx(40px, $max);
  width: apx(700px, $max);
  border-radius: apx(12px, $max);
  background-color: #000;
  padding: apx(24px, $max);
  box-sizing: border-box;
  position: fixed;
  left: 50%;
  bottom: apx(22px, $max);
  transform: translateX(-50%);
  z-index: 9999;

  @media #{$media-max} {
    gap: ims(40px);
    width: ims(700px);
    border-radius: ims(12px);
    padding: ims(24px);
    bottom: ims(22px);
  }

  @media #{$media-max-wrap} {
    gap: apx(40px, $max-wrap);
    width: apx(700px, $max-wrap);
    border-radius: apx(12px, $max-wrap);
    padding: apx(24px, $max-wrap);
    bottom: apx(22px, $max-wrap);
  }

  @media #{$media-phone} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: apx(20px, $phone);
    width: calc(100% - apx(32px, $phone));
    border-radius: apx(12px, $phone);
    padding: apx(24px, $phone);
    bottom: apx(16px, $phone);
  }

  &__text {
    font-size: apx(12px, $max);
    font-weight: $regular;
    line-height: 166.667%;
    color: #fff;

    @media #{$media-max} {
      font-size: ims(12px);
    }

    @media #{$media-max-wrap} {
      font-size: apx(12px, $max-wrap);
    }

    @media #{$media-phone} {
      font-size: apx(12px, $phone);
    }
  }

  &__btn {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: apx(48px, $max);
    height: apx(48px, $max);
    border: 1px solid #fff;
    background-color: #000;
    background-image: linear-gradient(-15deg, #fff 50%, transparent calc(50% + 1px));
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 250%;
    border-radius: apx(12px, $max);
    font-size: apx(16px, $max);
    font-weight: $regular;
    line-height: 150%;
    text-align: center;
    transition: background-position .6s $easeOutSine;

    @media #{$media-max} {
      width: ims(48px);
      height: ims(48px);
      border-radius: ims(12px);
      font-size: ims(16px);
    }

    @media #{$media-max-wrap} {
      width: apx(48px, $max-wrap);
      height: apx(48px, $max-wrap);
      border-radius: apx(12px, $max-wrap);
      font-size: apx(16px, $max-wrap);
    }
    
    @media #{$media-phone} {
      width: 100%;
      height: apx(40px, $phone);
      border-radius: apx(12px, $phone);
      background-image: linear-gradient(-2.5deg, #fff 50%, transparent calc(50% + 1px));
      font-size: apx(16px, $phone);
    }

    span {
      display: block;
      color: #fff;
      mix-blend-mode: difference;
    }

    &:hover,
    &:active {
      background-position: 0 100%;
    }
  }
}