@font-face {
  font-family: 'Mont';
  src: local('Mont-Regular'),
      url('/assets/fonts/Mont-Regular.woff2') format('woff2'),
      url('/assets/fonts/Mont-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: local('Mont-SemiBold'),
      url('/assets/fonts/Mont-SemiBold.woff2') format('woff2'),
      url('/assets/fonts/Mont-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: local('IBM Plex Serif'),
      url('/assets/fonts/IBMPlexSerif-Italic.woff2') format('woff2'),
      url('/assets/fonts/IBMPlexSerif-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}